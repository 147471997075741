import t from '@lib/locales';
import { Locales } from '@model/common';
import React from 'react';

type Props = {
  locale: Locales;
};

export const Loading: React.FC<Props> = ({ locale }) => {
  return (
    <div className="loading active">
      <div className="loading__body">
        <div className="loader"></div>
        <div className="loader__message">{t(locale, 'loading')}</div>
      </div>
    </div>
  );
};
