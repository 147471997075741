type Props = {
  symbol: string;
  price: string;
};

export const Price: React.FC<Props> = ({ symbol, price }) => {
  return (
    <>
      <span>{symbol}</span>&nbsp;<span>{price}</span>
    </>
  );
};
