export const formatPrice = (amount: number, currency = 'EUR', locale: string) => {
  const price = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(amount);
  return splitSymbolAndPrice(price);
};

export const splitSymbolAndPrice = (price: string) => {
  const match = price.match(/[^\d.,\s]+/);
  let symbol = match ? match[0] : '';

  const numericPrice = price.replace(symbol, '');

  symbol = symbol.replace('USD', '$');

  return { symbol, price: numericPrice };
};

export const detectFinalPath = (path: string, locale: string) => {
  const pathSegments = path.split('/');
  const lastSegment = pathSegments[pathSegments.length - 1];
  const finalPath = lastSegment ? lastSegment.split('?')[0] : undefined;

  return finalPath === locale ? 'homepage' : finalPath;
};
