import { Sku, SkuListItem } from '@commercelayer/sdk';
import { Level } from '@model/product';

export function getRequiredQuantity(skuListItems: SkuListItem[]) {
  return skuListItems.reduce((obj: any, i) => {
    const { sku_code, quantity } = i;
    obj[sku_code] = quantity;
    return obj;
  }, {});
}

/**
 * Check for bundle availability
 */
export function isBundleAvailable(skus: Sku[], requiredQuantity: Record<string, number>) {
  return skus.every(
    ({ code, inventory }) => inventory.available && inventory.quantity >= requiredQuantity[code]
  );
}

/**
 * Calculates the maximum number of bundles available based on SKUs
 */
export function getBundleMaxQuantity(skus: Sku[], requiredQuantity: Record<string, number>) {
  return skus.reduce(
    (min, { code, inventory }) =>
      Math.min(min, Math.floor(inventory.quantity / requiredQuantity[code])),
    Infinity
  );
}

export function getLevels(skus: Sku[], maxQuantity: number): Level[] {
  const allLevels: Level[] = (skus || []).reduce((all, s) => {
    if (!!s.inventory?.levels?.[0]) {
      return [...all, s.inventory.levels[0]];
    }
    return all;
  }, []);

  const deliveryLeadTimesValues = allLevels.reduce(
    (obj, level) => {
      const minDay = level.delivery_lead_times?.reduce((max, dlt) => {
        return Math.max(max, dlt.min.days);
      }, 0);
      const maxDay = level.delivery_lead_times?.reduce((max, dlt) => {
        return Math.max(max, dlt.max.days);
      }, 0);

      obj = {
        minDay: Math.max(obj.minDay, minDay),
        maxDay: Math.max(obj.maxDay, maxDay),
      };

      return obj;
    },
    { minDay: 1, maxDay: 1 }
  );

  const levels: Level[] = [
    {
      quantity: maxQuantity,
      delivery_lead_times: [
        {
          min: {
            days: deliveryLeadTimesValues.minDay,
            hours: deliveryLeadTimesValues.minDay * 24,
          },
          max: {
            days: deliveryLeadTimesValues.maxDay,
            hours: deliveryLeadTimesValues.maxDay * 24,
          },
        },
      ],
    },
  ];
  return levels;
}
