import { LineItem } from '@commercelayer/sdk';
import { AppCommerceLayerClient } from '@lib/AppCommerceLayerClient';
import { getMaxDeliveryDays } from '@lib/utils/clUtils';
import { State } from '@model/state';
import { getBundleAvailability, getProductAvailability } from './product-utils';

export const checkIfPossibleAddtoCart = (state: State, maxQtCL: number, skuCode: string) => {
  if (!state.order) {
    return true;
  }
  const quantityCart = getCartQuantityforProduct(state, skuCode)!;

  return quantityCart < maxQtCL;
};

export const getCartQuantityforProduct = (state: State, skuCode: string): number | undefined => {
  if (!state.order || !state.order.line_items) {
    return 0;
  }

  return (
    state.order.line_items.find((item) => item.sku_code === skuCode)?.quantity ||
    state.order.line_items.find((item) => item.bundle_code === skuCode)?.quantity ||
    0
  );
};

export const getOrderInfo = (lineItems: LineItem[]) => {
  return lineItems.reduce(
    (obj, i) => {
      if (i.metadata?.levels) {
        const { minDay, maxDay } = getMaxDeliveryDays(i.metadata?.levels);
        obj.maxDays = Math.max(maxDay, obj.maxDays);
        obj.minDays = Math.max(minDay, obj.minDays);
      }
      return obj;
    },
    { maxDays: 0, minDays: 0 }
  );
};

export const checkAndUpdateAvailabilities = async (
  client: AppCommerceLayerClient,
  lineItems: LineItem[] = []
) => {
  let orderIsChanged = false;
  for await (const lineItem of lineItems.filter(
    (l) => l?.item_type == 'skus' || l?.item_type == 'bundles'
  )) {
    const availability = lineItem.bundle_code
      ? await getBundleAvailability(client, lineItem.bundle_code)
      : await getProductAvailability(client, lineItem.sku_code);
    if (!availability.available) {
      orderIsChanged = true;
      await client.removeLineItem(lineItem);
    } else if ((lineItem.quantity || 0) > availability.maxQuantity) {
      orderIsChanged = true;
      await client.updateItemAvailability(lineItem.id, availability.maxQuantity);
    }
  }
  return orderIsChanged;
};
