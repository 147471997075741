'use client';

import { MediaBlockFragment } from '@graphql/generated';
import { useEffect, useState } from 'react';
import { Image as DatoImage } from 'react-datocms';
import ReactPlayer from 'react-player';

type Props = {
  block: MediaBlockFragment;
};

export const Media: React.FC<Props> = ({ block }) => {
  const { id, title, text, image, video, cssClass } = block;
  const [showReactPlayer, setReactPlayer] = useState(false);
  useEffect(() => {
    setReactPlayer(true);
  }, []);

  return (
    <div className={`media-block ${cssClass}`} key={id}>
      <div className="media-block__left">
        {title && <div className="media-block__title">{title}</div>}
        {text && (
          <div className="media-block__text">
            <span dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        )}
      </div>
      <div className="media-block__right">
        {image && <DatoImage className="media-block__image" data={image.responsiveImage!} />}
        {video && (
          <div className="media-block__video">
            {showReactPlayer && (
              <ReactPlayer
                controls={true}
                muted={true}
                width="100%"
                height="100%"
                url={video.url}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
