import { DatoImage } from '@components/image/DatoImage';
import { SlideFragment } from '@graphql/generated';

type Props = {
  slide: SlideFragment;
};

export const SlideshowElement: React.FC<Props> = ({ slide }) => {
  const { image, title, text } = slide;

  return (
    <>
      <div className="slideshow-slide">
        {title && <div className="slideshow-slide__title">{title}</div>}
        {image && (
          <div className="slideshow-slide__image">
            <DatoImage data={image.responsiveImage} />
          </div>
        )}
      </div>
      {text && <div className="slideshow-slide__text" dangerouslySetInnerHTML={{ __html: text }} />}
    </>
  );
};
